<template>
  <van-overlay :show="overlayVisible" :lock-scroll="true" style="z-index: 200">
    <img class="jd-bg" src="./21618_jd.png" @click="handleOpenCopy">
    <div class="close-btn" @click="overlayVisible = false" />
  </van-overlay>
</template>
<script>
import { Overlay, Toast } from 'vant'
import Vue from 'vue'
import { copyText } from '@/utils/common/utils'

Vue.use(Overlay)

export default {
  name: 'JDSixOneEight',
  data() {
    return {
      overlayVisible: false,
      jdText: '',
      jdUrl: ''
    }
  },
  methods: {
    /**
     * 处理打开京东活动弹框
     * 2021-05-25 10:51:05
     * @author SnowRock
     */
    handleOpenOverlay(jdText, jdUrl) {
      this.overlayVisible = true
      this.jdText = jdText
      this.jdUrl = jdUrl
    },
    /**
     * 复制及跳转至jd的地址
     * 2021-05-25 10:49:56
     * @author SnowRock
     */
    handleOpenCopy() {
      const { jdText, jdUrl } = this
      copyText(jdText, () => {
        Toast('复制成功')
        setTimeout(() => {
          window.location.href = jdUrl
        }, 800)
      })
    }
  }
}
</script>

<style scoped>
.jd-bg {
  width: 235px;
  height: 274px;
  position: absolute;
  left: 50%;
  top: 186px;
  margin-left: -112px;
}

.close-btn {
  width: 30px;
  height: 30px;
  background: url("~@/assets/images/x-on.png") no-repeat;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 480px;
  margin-left: -15px;
}
</style>
