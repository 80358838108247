import { render, staticRenderFns } from "./special.vue?vue&type=template&id=345d3831&scoped=true&"
import script from "./special.vue?vue&type=script&lang=js&"
export * from "./special.vue?vue&type=script&lang=js&"
import style0 from "./special.vue?vue&type=style&index=0&id=345d3831&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345d3831",
  null
  
)

export default component.exports