<template>
  <div class="navi_gation_box">
    <div v-if="navigation" class="must_buy_tab">
      <van-tabs
        v-model="active"
        title-active-color="#E33F44"
        title-inactive-color="#333"
        color="#E33F44"
        line-width="30px"
        @change="changeJdTab"
      >
        <van-tab v-for="(item,index) in navigation.topLabel" :key="index" :title="item.name" />
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Lazyload, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'

Vue.use(Tab).use(Tabs).use(Toast).use(Lazyload)
export default {
  name: 'Navigation',
  props: {
    navigation: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      active: ''
    }
  },
  mounted() {
  },
  methods: {
    changeJdTab: function(t) {
      this.$emit('navigationData', t)
    }
  }
}
</script>

<style lang="less" scoped>
.navi_gation_box {
  width: 100%;
  height: 44px;

  /deep/ .van-tabs__line {
    bottom: 22px;
  }
}

</style>
