<template>
  <!--头部-->
  <!-- ,background:bgImg?'url('+bgImg+')':'#fff', -->
  <!-- ,'background-image':bgImg?'url('+bgImg+')':'#fff','background-size':'cover', -->
  <div ref="yue_tao_box" class="yue_tao_box" :style="{height:'100vh'}">
    <!--头部-->
    <headTops ref="headTops" />
    <!--搜索-->
    <headSearch ref="headSearch" :search-out="headSearch" />
    <!--导航-->
    <naviGation ref="naviGat" :navigation="RecommendAll" @navigationData="indexData" />
    <!--list 列表-->
    <!--精选推荐-->
    <selectedRecommend
      v-if="isTabIndex===0"
      :recommend-all="RecommendAll"
      :settop="settop"
      @tabIndex="homeIndex"
    />
    <!--线上体验店-->
    <!-- <div v-if="isTabIndex==1" >1</div> -->
    <!--悦淘特价-->
    <special v-if="isTabIndex===1" :settop="settop" />
    <!--爆款拼团-->
    <bigMoney v-if="isTabIndex===2" :settop="settop" />
    <!--美妆个户-->
    <beautifulClothes v-if="isTabIndex===3" :settop="settop" />
    <!--食品生鲜-->
    <!--底部tab-->
    <bootomTabbar />
  </div>
</template>

<script>
import headTops from '@/component/yueTao/headTop'
import headSearch from '@/component/head/search'
import naviGation from '@/component/yueTao/naviGation'
import bootomTabbar from '@/component/Tabbar/index'
import selectedRecommend from '@/component/yueTao/recommend'
import special from '@/component/yueTao/special'
import bigMoney from '@/component/yueTao/bigMoney'
import beautifulClothes from '@/component/yueTao/beautifulClothes'
import { newIndexHome } from '@/services/api'

export default {
  name: 'YueTap',
  components: {
    headTops,
    headSearch,
    naviGation,
    bootomTabbar,
    selectedRecommend,
    special,
    bigMoney,
    beautifulClothes
  },
  data() {
    return {
      active: '',
      headSearch: {
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: false, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#fff', // 背景颜色
        placeholder: ' ',
        callbackClick: this.callbackClick, // 回调
        callbackBtnGo: this.callbackBtnGo
      },
      isTabIndex: 0, // tab导航 index
      navigation: {}, // tab导航 data
      bannerlist: {}, // 首页
      RecommendAll: null, // 精选推荐 data list
      settop: 0,
      bgImg: ''
    }
  },

  mounted() {
    this.settop = this.$refs.headTops.$el.offsetHeight + this.$refs.headSearch.$el.offsetHeight + 47
    this.gitNavigation()// tab导航
  },
  methods: {
    // 搜索回调
    callbackBtnGo: function() {
      this.$router.push('./goodsSearch')
    },

    //  获取  list 列表
    gitNavigation: function() {
      const data = {
        uid: window.localStorage.getItem('uid')
      }
      newIndexHome(data).then(res => {
        if (Number(res.code) === 200) {
          this.headSearch.placeholder = res.data.searchWord.searchWord
          if (res.data.colorImage) {
            this.bgImg = res.data.colorImage
          }

          if (res.data.topLabel) {
            res.data.topLabel = res.data.topLabel.filter(e => {
              return e.id !== '413'
            })
          }
          this.navigation = res.data.topLabel
          let index = 0
          const newArray = []
          while (index < res.data.wapIcon.length) {
            newArray.push(res.data.wapIcon.slice(index, index += 10))
          }

          res.data.wapIcon = newArray
          this.RecommendAll = res.data
        }
      })
    },
    // // 导航 tab  下标切换 复值 isTabIndex
    indexData: function(t) {
      this.isTabIndex = t
    },
    homeIndex: function(r) {
      this.isTabIndex = r
    }
  }

  // beforeRouteLeave(to, from, next) {

  //       let position = document.documentElement && document.documentElement.scrollTop; //记录离开页面时的位置

//       if (position == null) position = 0
//       this.$store.commit('ScrollDateChange', position) //离开路由时把位置存起来
//       next()
// }
}
</script>

<style lang="less" scoped>

.yue_tao_box {
  text-align: left;
  background: #f6f6f6 left top;
}

</style>
