<template>
  <div class="head_box">
    <div class="head_box_ov">
      <div v-if="isLogin" class="head_top_l">
        <div class="head_top_img">
          <img v-if="!userarvrimg" src="@/assets/images/defaulthead.png">
          <img v-else :src="userarvrimg" alt="">
        </div>
        <div class="head_top_title">
          <h5>{{ nickname }}</h5>
          <h6>{{ levelText }}</h6>
        </div>
      </div>
      <div v-else class="head_top_l" @click="gologin">
        <div class="head_top_img">
          <img src="../../assets/images/default.png" alt="">
        </div>
        <div class="head_top_title">
          <h4>登录</h4>
        </div>
      </div>
      <div class="head_top_r">
        <!-- <div class="head_top_news">
          <div class="head_top_right_img"><img src="../../assets/images/default.png" alt=""></div>
          <div class="head_top_right_name">消息</div>
        </div> -->
        <div class="head_top_type" @click="linkTo()">
          <div class="head_top_right_img"><img src="../../assets/images/classifIcon.png" alt=""></div>
          <div class="head_top_right_name">分类</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getusernew } from '@/services/userApi'
import { Toast } from 'vant'

export default {
  name: 'HeadTop',
  props: {
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLogin: false, // 用户登录状态，默认未登录
      userinfo: '', // 用户信息
      userarvrimg: ''
    }
  },
  computed: {
    ...mapGetters([
      'uid', 'userarvr', 'nickname', 'levelText'
    ])
  },
  mounted() {
    this.getUserLogin()// 获取用户登录状态
  },
  methods: {
    linkTo: function() {
      this.$router.push({ path: '/classification' })
    },
    gologin() {
      this.$router.push({ path: '/login' })
    },
    // 获取用户登录状态
    getUserLogin() {
      var that = this
      var uid = localStorage.getItem('uid')
      if (uid) {
        that.isLogin = true
        this.getuserinfo()
      }
    },
    // 个人信息查询
    getuserinfo() {
      getusernew().then(res => {
        if (Number(res.code) === 200) {
          this.userarvrimg = res.data.avatar
        } else {
          Toast(res.msg)
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.head_box {
  width: 100%;
  padding: 10px 12px 5px 12px;
  box-sizing: border-box;
  background: #fff;

  .head_box_ov {
    overflow: hidden;

    .head_top_l {
      float: left;

      .head_top_img {
        width: 40px;
        height: 40px;

        float: left;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border: 2px solid orange;
          border-radius: 50%;

        }
      }

      .head_top_title {
        float: left;
        margin-left: 12px;

        h4 {
          margin-top: 12px;
        }

        h5 {
          font-size: 15px;
          margin-top: 2px;
          color: #333;
          font-weight: 600;
        }

        h6 {
          margin-top: 6px;
          font-size: 10px;
          color: #333;
        }
      }
    }

    .head_top_r {
      float: right;
      margin-top: 2px;

      .head_top_right_img {
        text-align: center;

        img {
          width: 18px;
        }
      }

      .head_top_news {
        float: right;
        margin-left: 12px;
      }

      .head_top_type {
        float: right;
      }

      .head_top_right_name {
        font-size: 10px;
        margin-top: 2px;
      }

    }
  }

}

</style>
