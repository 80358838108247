<template>
  <div class="special_all">

    <skeleton v-if="!dataALL" type="indexList" />
    <mescroll v-else :up="mescrollUp" :down="mescrollDown">

      <div slot="scrollList" class="recommend_box">
        <div class="special_box">
          <div class="special_box_img">
            <img :src="dataALL.bgColor" alt="">
          </div>
        </div>
        <!--tab 1-->
        <div class="special_tab_box">
          <div class="must_buy_tab">
            <van-tabs
              v-model="active"
              title-active-color="#F30002"
              title-inactive-color="#141F33"
              color="red"
              line-width="76px"
              line-height="0"
              @click="changTab"
            >
              <van-tab
                v-for="(item,index) in dataALL.categoryList"
                :key="index"
                :title="item.name"
                :name="item.categoryId"
              />
            </van-tabs>
          </div>
        </div>
        <!--tab 2-->
        <div class="special_tab_type">
          <div class="special_tab_list" @click="isTab=0,btnTab(1)">
            <span :class="isTab==0? 'bf' :''">销量
              <div class="imgs" />
            </span>

          </div>
          <div class="special_tab_list" @click="isTab=1,btnTab(2)">
            <span :class="isTab==1? 'bf' :''">价格
              <div class="imgs" />
            </span>
          </div>
        </div>
        <!--tab 2-->
        <div class="special_title">
          <div v-if="times" class="special_title_top">
            <span class="special_title_r">活动倒计时</span>
            <span class="open_time">{{ times.h }}</span>
            <span>:</span>
            <span class="open_time">{{ times.m }}</span>
            <span>:</span>
            <span class="open_time">{{ times.s }}</span>
          </div>
          <div v-for="(item,index) in dataAll" :key="index" class="special_title_list" @click="btnDataAll(item)">
            <div class="special_title_list_img">
              <imgLoading :src="item.path" />
            </div>
            <div class="special_title_name">{{ item.name }}</div>
            <div class="special_title_price">全网价{{ item.price }}</div>
            <div class="special_title_img"><img src="../../assets/images/bjlogo.png" alt=""></div>
            <div class="special_title_data">限时价¥{{ item.vipPrice }}</div>
          </div>
        </div>
        <!--3-->
      </div>
    </mescroll>
  </div>

</template>

<script>
/* eslint-disable eqeqeq */
import skeleton from '@/component/skeleton/index'
import imgLoading from '@/component/imgLoading'

import { Lazyload, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'
import { countTime } from '@/utils/public'

Vue.use(Tab).use(Tabs).use(Toast).use(Lazyload)
import { getSpecialOffer } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'Special',
  components: {
    mescroll, skeleton, imgLoading
  },
  props: {
    settop: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      active: '',
      dataALL: null,
      dataAll: '',
      isTab: 0, // tab
      isTypeTab: 1, // 1 销量排序  2 价格排序  默认销量排序
      categoryId: 8792, // 分类id   (家居：8783 食品：8792 母婴：8791 服饰：8785 考拉：111  当当：222) 默认日用
      sort: 'asc', // 升序：asc 降序：desc 默认降序
      is: false,
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00',
      timer: '',
      timers: null,
      times: null,
      mescrollDown: {
        use: true,
        top: '144px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }

    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.settop !== 0) {
        this.mescrollDown.top = this.settop + 'px'
      }
    })
    this.getSpecialOffer()
  },
  methods: {
    changTab: function(name) {
      this.categoryId = name
      this.getSpecialOffer()
      clearInterval(this.timer)
    },
    btnTab: function(t) {
      this.isTypeTab = t
      if (t == 1) {
        this.is = !this.is
        if (this.is) {
          this.sort = 'desc'
        } else {
          this.sort = 'asc'
        }
      } else if (t == 2) {
        this.is = !this.is
        if (this.is) {
          this.sort = 'desc'
        } else {
          this.sort = 'asc'
        }
      }
      this.getSpecialOffer()
      clearInterval(this.timer)
    },
    getSpecialOffer: function() {
      const data = {
        categoryId: this.categoryId,
        sortType: this.isTypeTab,
        sort: this.sort, // 升序：asc 降序：desc 默认降序
        page: 1,
        pageNum: 10
      }

      getSpecialOffer(data).then(res => {
        clearInterval(this.timer)
        if (Number(res.code) === 200) {
          this.dataALL = res.data
          const data = res.data.todayEndTime
          this.dataAll = res.data.goodsList
          this.startTimer(data)
        }
      })
    },
    startTimer(totalSecond) {
      const _that = this
      this.timers = setInterval(() => {
        _that.times = countTime(totalSecond)
      }, 1000)
    },
    mescrollInit(data) {
      this.mescroll = data
    },

    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10  // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.dataAll = []
          // 把请求到的数据添加到列表
          this.dataAll = this.dataAll.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },

    // list
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        categoryId: this.categoryId,
        sortType: this.isTypeTab,
        sort: this.sort, // 升序：asc 降序：desc 默认降序
        page: pageNum,
        pageNum: pageSize
      }
      getSpecialOffer(data).then(res => {
        successCallback(res.data.goodsList)
      })
    },
    btnDataAll: function(t) {
      const obj = {
        product_type: t.product_type,
        goodId: t.goodsId,
        skuId: t.skuId
      }

      this.$store.dispatch('godetail', obj)
    }

  }
}
</script>

<style scoped lang="less">
.special_all {
  .special_box {
    background: #f6f6f6;

    .special_box_img {
      width: 100%;
    }

    .special_tab_box {
      width: 100%;
      height: 50px;
      background: #f6f6f6;
    }
  }

  .special_tab_type {
    display: flex;

    .special_tab_list {
      flex: 1;
      text-align: center;
      padding: 12px;
      color: #f3420d;
    }

    .special_tab_list {
      background: #fff;

      span.bf {
        background: #f3420d;
        padding: 4px 12px;
        border-radius: 16px;
        color: #fff;

        .imgs {
          background: url("../../assets/images/paixushengxu.png") no-repeat;
          width: 12px;
          height: 12px;
          background-size: 100% 100%;
          display: inline-block;
        }
      }

    }
  }

  .special_title {
    margin: 0 12px;
    padding-bottom: 12px;
    overflow: hidden;

    .special_title_top {
      color: #f3420d;
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: rgba(177, 73, 56, .14);
      border-radius: 4px 4px 0 0;
      padding-left: 12px;

      .special_title_r {
        margin-right: 12px;
      }

      .open_time {
        background: #f3420d;
        line-height: 42px;
        border-radius: 4px;
        padding: 2px;
        color: #FDF2EF;
      }
    }

    .special_title_list {
      width: 117px;
      height: 220px;
      background: #fff;
      float: left;
      padding: 4px;
      box-sizing: border-box;
      border-right: 1px solid #f2f2f2;
      border-top: 1px solid #f2f2f2;

      .special_title_list_img {
        width: 110px;
        height: 110px;
      }

      .special_title_name {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
        padding: 2px;
        font-size: 10px;
        overflow: hidden;
        height: 40px;
      }

      .special_title_price {
        text-align: center;
        font-size: 10px;
        margin-top: 4px;
        color: #999;
        text-decoration: line-through;
      }

      .special_title_img {
        width: 80px;
        margin: 3px auto;

        img {
          width: 100%;
        }
      }

      .special_title_data {
        text-align: center;
        font-size: 12px;
        margin-top: 4px;
        color: red;
      }
    }

    .special_title_list:nth-child(3n) {
    }

  }

}

</style>
